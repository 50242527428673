import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import Layout from "@components/layout";
import SEO from "@components/seo";
import YouTubeVideoFrame from "@components/videos/youtube-video.jsx";

import styles from "@scss/tags.module.scss";
// import "./../scss/news.scss";
// import "./../scss/page.scss";

const TagPage = ({ data }) => {
  let videos = get(data, "prismic.allVideos.edges");
  return (
    <Layout>
      <SEO title="News" />
      <section className={styles.tags}>
        <div className={styles.tagsInner}>
          <h2>Tag Page</h2>
          <ul className={styles.tagsList}>
            {videos &&
              videos.map((video, index) => {
                return (
                  <li key={index} className={styles.tagsItem}>
                    <YouTubeVideoFrame video={video} />
                  </li>
                );
              })}
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export default TagPage;

export const pageQuery = graphql`
  query videoTagQuery($tag: [String!]) {
    prismic {
      allVideos(tags: $tag) {
        edges {
          node {
            title
            youtube_id
            _meta {
              uid
              tags
            }
          }
        }
      }
    }
  }
`;
